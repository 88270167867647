.calltoaction
  width 85%
  max-width 375px
  margin 4rem auto
  text-align center
  line-height 1.5em
  font-size 1.5rem
  &__icon
    margin 1rem 0 1.5rem 0
  &__dachzeile
    font-weight $light
  &__text
    font-weight $bold


+above(768px)
  .calltoaction
    width 85%
    margin 5rem auto