/*SERVICES ICONS AS BACKGROUND*/
.services
  width 100%
  margin 5rem 0
  &__content
    width 75%
    max-width 950px
    margin 0 auto
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content center
    gap 30px
  &__card
    width 345px
    height 345px
    display flex
    flex-direction column
    align-items center
  &__image
    background #fefefe
  &__title
    color $secondary
    font-size 1.5rem
    text-transform uppercase
    margin-top -70px

