#page__header
  position fixed
  top 0
  left 0
  padding 2rem 0 0 2rem
  width 100%
  display flex
  flex-flow row nowrap
  justify-content space-between
  height 85px
  z-index 1000
  margin 0 0 0 0
.logo__container
  position absolute
  z-index 1001
  top 2rem
  right 6rem
  margin-top -10px
  &--home
    display none
    right 20px
  &--main
    display inline-block
    right 20px
    margin-top -10px
#home, #visionen
  .logo__container--main
    display none
  .logo__container--home
    display inline-block
body:not(#home):not(#visionen)
  #page__header
    background-color $white
  .logo__container--home
    display none
  .logo__container--main
    display inline-block

+above(768px)
  .logo__container
    position absolute
    z-index 1001
    top 2rem
    right 6rem
    margin-top 0
    &--home
      display none
    &--main
      display inline-block


