/*Layout*/
.content_wrapper
  //padding 0 50px 50px 35px
  padding 0
  display flex
  flex-direction column
  margin-top 20vh
#home
  .content_wrapper
    margin-top 110vh


.container
  width 90%
.abstand-oben
  margin-top 5em!important
.abstand-unten
  margin-bottom 5em!important
.ce-table-bordered
  border 1px solid $primary

table
  font-family $mainfont
  td
    border-right 1px solid #ccc

+above(1024px)
  .container
    width 80%
    max-width 980px
    margin 0 auto


+above(1920px)
  body
    font-size 22px
  .container
    width 80%
    max-width 1440px


