.navigation
  z-index 500
  background-color $white
  &__megamenu
    display none
    position absolute
    left 0
    background-color $white
    z-index 900
  &__container
    background-color $white
    display flex
    flex-direction column
    align-content flex-start
    width 100%
    position fixed
    top 0px
    padding-top 60px !important
    padding-left 0.5rem
    z-index 901
  &__trigger
    background url('/typo3conf/ext/joitzalangbecker2022/Resources/Public/Icons/Menu.svg') no-repeat top left
    z-index 901
    height 19px
    width 23px
    position absolute
    top 2rem
    left 2rem
    img
      height 1.2rem
      width 1.2rem
      z-index 901
  &__main
    width 25%
    display flex
    flex-direction row
    flex-wrap wrap
    gap 100px
    li
      font-size 0.9rem
      font-weight $bold
      text-transform uppercase
      line-height 1.5em
  &__sub
    li
      font-weight  $light
      text-transform  none
      line-height 1.5em
  &__mid
    display flex
    flex-direction column
    flex-wrap wrap
    gap 1px
    margin-right 6rem
    li
      font-weight $bold
      text-transform  uppercase
      line-height 1.5em
      font-size 0.9rem
  &__right
    display flex
    flex-direction column
    flex-wrap wrap
    gap 1px
    li
      font-weight $bold
      text-transform  uppercase
      line-height 1.5em
      font-size 0.9rem

#home
  .navigation__trigger
    background-image url('/typo3conf/ext/joitzalangbecker2022/Resources/Public/Icons/Menu-over.svg')

nav.pagenavigation
  margin 5rem auto
  color $secondary
  display flex
  justify-content center
  width 50%
  background-color $white
  a
    color $secondary
    padding-right 10px
  ul
    display flex
    flex-direction row
    justify-content center
    flex-wrap wrap
    column-gap 20
    gap 2rem
  li
    color $secondary
    text-transform uppercase
    font-size 1.2rem
    gap 20
.scrolled
  position fixed
  margin-top -25px
  top 0px
  left 505px
  margin 0 auto
  width 30%

+above(768px)
  .navigation
    &__container
      padding 2rem
      flex-direction row
      flex-wrap wrap

+above(1024px)
  foo bar


+above(1920px)
  foo bar
