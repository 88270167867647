/*Variables*/

/*COLORS*/
$white = rgba(255, 255, 255, 1)
$black = rgb(0, 0, 0)
$grey = #73787D

$primary = #73787D
$primary-variant = #841218
$primary--transparent = #FFA68B
$secondary = #008CC8
$secondary-variant = #7E87A6

/* lato-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/lato-v24-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/lato-v24-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/lato-v24-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lato-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../Fonts/lato-v24-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$light = 300
$normal = 400
$bold = 700
$bolder = 900

//font: font-style font-variant font-weight font-size/line-height font-family|caption|icon|menu|message-box|small-caption|status-bar|initial|inherit;

$mainfont =  'Lato', Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif"
$titlefont =  'Lato', Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif"
//$seriffont =  "IBM Plex Serif", Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif"
//$italicfont = "IBM Plex Serif", Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif"