footer
  background-color $white
  color $primary
  padding 35px 50px 50px 35px //in rem umwandeln
  a
    color $primary
.sitefooter
  &__content
    display flex
    flex-direction row
    justify-content space-between
  &__id
    margin-right 5rem
    text-align left
  &__menu
    margin-right 5rem
    text-align right
  &__nav
    display flex
    flex-direction row
    flex-wrap wrap
    text-align left
    justify-content right
    gap 15px



+above(1024px)
  .sitefooter
    &__content
      display flex
      flex-direction row
      justify-content space-between
    &__id
      margin-right 5rem
      text-align left
    &__menu
      margin-right 5rem
      text-align right
    &__nav
      display flex
      flex-direction row