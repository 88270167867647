#hero
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  display flex
  flex-direction column
  align-items center
  z-index 0
.hero
  &__slide
    position absolute
    background-color $primary
    filter contrast(90%) brightness(0.5)
    height 100%
    width 100%
    background-size cover
    background-repeat no-repeat
  &__container
    z-index 150
    height 400px
    position absolute
    top 45%
    left calc(50% - 300px)
    padding 0 35px 10px 35px
    img
      height 85%
    h1
      color $white
      font-weight 400
      margin-top 25px
      display flex
      align-items center
#hero
  .heroslider
    width 100%
    height 100%

  .swiper-slide
    text-align center
    font-size 18px
    background #fff
    display flex
    justify-content center
    align-items center
    img
      display block
      width 100%
      height 100%
      object-fit cover
#visionen .hero__slide
  filter none
