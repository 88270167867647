/*base*/
body
  margin 0
  font-size 16px
  font-family $mainfont
  scroll-behavior smooth

h1,h2,h3,h4,h5
  font-family $titlefont
  letter-spacing 1px
  font-weight 400
  margin-top 0
  font-weight $light
  text-rendering optimizeLegibility
h1
  font-size 3rem
  color $primary
  text-transform uppercase
  hyphens auto

h2
  font-size 3rem
  line-height 3.2rem
  color $primary
  text-transform uppercase
  hyphens auto

h3
  font-size 2.65rem
  line-height 3.2rem
  color $primary
  margin-bottom 1.7rem
  hyphens auto

h4
  font-size 1.2rem
  margin-block-start 1.33em
  margin-block-end 0.75em

h5
  font-size 1rem

nav,ul,li,p
  color $primary
  font-family $mainfont
  font-weight $light
  list-style none
  font-size 1rem
p
  //letter-spacing 1px
  font-size 1rem
  line-height 1.5rem
  font-weight $light

/*Listen*/

ul, li
  margin-left 0
  padding-left 0
  color $primary

nav li
  padding 2px 0
  color $primary

/*TABLE*/
table
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  font-size 1rem
  td
    text-align: left;
    padding: 8px;
  tr:nth-child(even)
    background-color: #f2f2f2
.tableContainer
  overflow overlay
  width 90%
  margin 0 auto
  .ce-headline-center
    margin-bottom 4rem
    hyphens auto



/*LINKS*/
a
  text-decoration none
  color $primary
  font-family $mainfont
  &:hover
    text-decoration underline
    color $secondary
.active a
  color $secondary

figure
  margin 0

.container
  ul
    margin-left 1.1rem
    li
      list-style disc
      font-size 1rem
      font-family $mainfont
      letter-spacing 1px

/* Layout base*/
.paragraph
  font-family $mainfont
  font-size 17px
  line-height 24px
  max-width 522px
  width 522px

.light
  font-family $light
  font-weight 300
  font-size 1rem
  line-height 1.4rem

.buttons
  display flex
  flex-direction row
  margin-top 50px
  margin-bottom 100px
  font-weight  $bold

.btn
  border-bottom 1px solid $black
  color $white
  background-color $primary
  width 100px
  height 50px
  padding 10px
  text-align center
  font-size 14px
  font-family $mainfont
  font-weight 300
  margin-right 50px

.btn-primary__a
  font-family $mainfont
  font-weight  $light

.btn-primary__b
  font-family $light
  font-weight 300

/* Standart Helper*/
.ce-headline-center
  text-align center
  margin 0 auto
  hyphens auto


/*Margins*/
.frame-default
  margin 0 auto
  width 80%


.frame-space-before-extra-small
  margin-top 1rem
.frame-space-before-small
  margin-top 2rem
.frame-space-before-medium
  margin-top 6rem!important
.frame-space-before-large
  margin-top 10rem
.frame-space-before-extra-large
  margin-top 15rem

.frame-space-after-extra-small
  margin-bottom 1rem
.frame-space-after-small
  margin-bottom 2rem
.frame-space-after-medium
  margin-bottom 6rem!important
.frame-space-after-large
  margin-bottom 10rem
.frame-space-after-extra-large
  margin-bottom 15rem



.element__wrapper
  margin 0
  width 85%



.ce-textpic
  display flex
  flex-direction column
  flex-wrap wrap
  justify-content center
  gap 25px
  figure
    margin-right 0rem

.ce-bodytext
  font-size 1.2rem
  line-height 1.75rem
  max-width 100%

+above(475px)
  .ce-bodytext
    font-size 1.2rem
    line-height 1.75rem


  .ce-textpic
    figure
      margin-right 0rem


+above(768px)
  h1, h2
    font-size 3.5rem
    line-height 3.5rem
  .frame-default
    margin 0
    width 100%
  .ce-bodytext
    max-width 56%
  .ce-headline-center
    text-align center
    margin 0 auto
    width 60%
    hyphens auto
  .ce-textpic
    display flex
    flex-direction row
    justify-content flex-start
  .element__wrapper
    margin 0 auto



+above(1024px)
  .ce-bodytext
    font-size 1.2rem
    line-height 1.75rem


+above(1144px)

  .ce-textpic
    figure
      margin-right 8rem



+above(1302px)
  .element__wrapper
    margin 0 auto

  .ce-textpic
    figure
      margin-right 14rem
  .ce-bodytext
    font-size 1.2rem
    line-height 1.75rem
    max-width 720px


+above(1920px)
  body
    font-size 22px




