.gallery
  display flex
  flex-wrap wrap
  justify-content space-between
  margin 0 auto
  max-width 110%
  margin-bottom 5rem
  padding 0
  width 105%
  margin 0 0 8rem 0
  flex-direction column
  gap 2rem
  h2
    text-align center
  &__overlay
    display none
    flex-direction column
    justify-content flex-end
    background-color rgba(0,0,0,0.60)
    background-size cover
    color #fff
    padding 2rem 2rem 6rem 4rem
    height 100%
    h3
      font-size 2.75rem
      color $white
      font-weight 300
      text-transform uppercase
      margin-bottom 2rem
    p
      font-size 1rem
      color $white
      font-weight 300
      margin-bottom 0
  &__description
    p
      font-size 2.2rem
      line-height 2.75rem
      color $white
      font-weight 300
      text-align left
  &__grid
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-template-rows 1fr 1fr 1fr
    width 100%
    gap 15px
    .gallery__item
      background-position center
      width 100%
      background-size cover
      height 600px
      min-height 500px
    .gallery01
      grid-column 1/3
      grid-row 1/2
    .gallery02
      grid-column 3/4
      grid-row 1/2
    .gallery03
      grid-column 1/2
      grid-row 2/3
    .gallery04
      grid-column 2/4
      grid-row 2/3
    .gallery05
      grid-column 1/3
      grid-row 3/4
    .gallery06
      grid-column 3/4
      grid-row 3/4
    .gallery01:hover
      .gallery__overlay
        display flex
    .gallery02:hover
      .gallery__overlay
        display flex
    .gallery03:hover
      .gallery__overlay
        display flex
    .gallery04:hover
      .gallery__overlay
        display flex
    .gallery05:hover
      .gallery__overlay
        display flex
    .gallery06:hover
      .gallery__overlay
        display flex