.contact
  display flex
  flex-direction column
  width 85%
  align-items center
  margin 5rem auto
  &__cards
    display flex
    flex-direction row
    flex-wrap wrap
    gap 50px
    margin 3rem auto
    width 100%
    justify-content center
  &__carditem
    width 278px
    font-size 1rem
    font-weight $light
    text-align center
    align-items center
    &:nth-of-type(even) img
      border-radius 0 0 0 16px
    &:nth-of-type(odd) img
      border-radius 0 16px 0 0
  &__foto
    margin-bottom 1rem
  &__icon
    text-align center
    align-items center
    p
      font-size 0.9em
    img.svg:hover
      .cls-1
        fill #008cc8




+above(768px)
  .contact
    width 80%