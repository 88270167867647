.introtext
  &__content
    width 85%
    margin 0 auto
  &__title
    text-align left
    font-size 2.5rem
    text-transform none
    margin-bottom 1rem
  &__textcontent
    p
      font-size 1.2rem
      line-height 1.5rem
  &__link
    font-size 0.9rem
    letter-spacing 1px
    text-transform uppercase
    font-weight $bold

+above(768px)
    .introtext
      &__content
        width 60%
        margin 0 auto
