.sections
  width 100%
  margin 10rem 0 5rem 0
  &__content
    width 100%
    max-width 1920px
    margin 0 auto
    display flex
    flex-direction row
    flex-wrap wrap
    gap 20px
  &__card
    display flex
    flex-direction column
    align-items center
    width calc(25% - 15px)
    height auto
    a
      text-align center
  &__image
    width 400px
    height 300px
    aspect-ratio 1/1
    background #fefefe
    margin-bottom 15px
    img
      height 300px!important
      width 400px!important
  &__title
    color $secondary
    font-size 1.2rem
    text-transform uppercase

